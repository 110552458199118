<template>
	<!-- 隐私协议 -->
	<div class="privacyAgreement">
		<div class="back" @click="goBack">/ 返回上一页</div>
		<div class="content">
			<iframe
				src="http://html.xmqiufeng.top/#/agreement?type=PrivacyAgreement"
				frameborder="0"
				width="100%"
				height="100%"
			></iframe>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PrivacyAgreement',
	methods: {
		goBack() {
			this.$router.go(-1)
		}
	}
}
</script>

<style scoped lang="scss">
.privacyAgreement {
	width: 100%;
	font-size: 14px;
	box-sizing: border-box;
	.back {
		width: 120px;
		font-size: 14px;
		cursor: pointer;
		padding: 20px;
	}
	.content {
		width: 100%;
		height: calc(100vh - 80px);
	}
}
</style>
